.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.main-content {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  flex: 1;
}

.modal_input {
  margin-top: 40px;
  margin-bottom: 20px;
}

.modal_login {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}