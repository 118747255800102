.CatalogBlock {
}

.container {
  width: 82%;
  padding: 40px 0;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.title {
  color: #212121;
  font-family: 'Factor A', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 110%;
  text-align: center;

  span {
    color: #F63939;
  }
}

.greyCatalog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.catalog {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  width: 100%;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #fff;
  padding: 8px 16px;
  color: #212121;
  transition: all .2s;

  p {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
  }

  .image {
    display: flex;
    width: 56px;
    height: 54px;

    img {
      margin: auto;
      width: 54px;
      max-height: 54px;
    }
  }

  &:hover {
    box-shadow: 3px 3px 30px 0px rgba(32, 30, 54, 0.15);
  }

}

@media (max-width: 1024px) {
  .catalog {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    gap: 24px;
    padding: 24px 0;
  }

  .greyCatalog {
    gap: 24px;
  }

  .catalog {
    grid-gap: 16px;
  }

  .title {
    font-size: 36px;
  }
}

@media (max-width: 600px) {
  .catalog {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
}
