:root {
  --main-font: Inter, sans-serif;

  //Colors
  --primary: #212121;
  --secondary: #5F6275;
  --secondary-grey: #6C6E79;

  --form3-card-radius: 0;
  --form3-card-background: none;

  --primary-color: #F63939;
  --button-hover: #D22E2E;
  --button-active: #AA1F21;

  --border-radius-btn: 2px;
  --transition-button: all 0.4s ease-in-out 0s
}