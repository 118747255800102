.NavBar {
  max-width: 1440px;
  margin: 0 auto;
  height: 67px;
  width: 100%;

  display: flex;
  align-items: center;
}

.container {
  margin: 0 auto;
  width: 82%;

  display: flex;
  gap: 50px;
}

.navLinks {
 display: block;
}

.list {
  display: flex;
  gap: 24px;
}

.link {
  color: #6C6E79;
  font-weight: 500;
  line-height: 143%;

  &:hover {
    color: #F63939;
  }
}

.navBurger {
  position: relative;
  z-index: 999;
  display: none;
  width: 24px;
  height: 24px;
  background: url("../../assets/image/IconBurger.svg");
}
.burgerClose {
  background: url("../../assets/image/IconBurgerClose.svg");
}

@media (max-width: 768px) {
  .NavBar {
    height: 50px;
    margin-top: 24px;
  }

  .container {
    width: 90%;
  }

/*  .navLinks {
    display: none;
    background: #212121;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }*/

  .list {
    margin: 20% auto;
    flex-direction: column;
    font-size: 16px;
    text-align: center;
  }

  .navLinksShow {
    display: flex;
  }

  .navBurger {
    display: block;
  }
}
