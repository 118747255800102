@import "assets/styles/reset.scss";
@import "assets/styles/vars";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@font-face {
  font-family: "Factor A";
  src: url("./assets/fonts/FactorA-Bold.ttf");
  font-weight: 700;
}

html {
  background: #EEE;
  scroll-behavior: smooth;
}

body {
  font-family: var(--main-font);
  font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
